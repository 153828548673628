import Header from "../../components/header/header";
import BigSearchButton from "../../components/bigsearchbutton/bigsearchbutton";

export default function Root() {
    return (
        <>
            <Header />
            <BigSearchButton/>
        </>
    )
}