import './bigsearchbutton.css'
import {useContext, useState} from "react";
import {LocaleContext} from "../../contexts/localecontext";
import {createSearchParams, useNavigate} from "react-router-dom";

export default function BigSearchButton() {
    const locale = useContext(LocaleContext);
    const navigate = useNavigate();
    const [bigSearch, setBigSearch] = useState("")

    const handleChangeBigSearch = (e) => {
        setBigSearch(e.target.value)
    }

    const handleClickBigSearch = () => {
        navigate({
            pathname: "/search",
            search: createSearchParams({
                q: bigSearch
            }).toString()
        })
    }

    const handleBigKeyDown = (e) => {
        if (e.key === "Enter") {
            handleClickBigSearch()
        }
    }

    return (
        <>
            <div className={"d-flex flex-column justify-content-center vh-center-size font-elite"}>
                <div className={"container-searchbox mx-auto"}>
                    <div className={"d-flex rounded-top-3 color-green-d1"}>
                        <p className={"text-nowrap text-uppercase text-white fw-bold m-0 ms-auto me-auto mt-1 mb-1"}>
                            {(locale === "sk" && "Hladať v databáze") || "Search in database"}
                        </p>
                    </div>
                    <div className={"d-flex color-green-d2 p-3"}>
                        <div className="input-group">
                            <input aria-describedby="search-button" className="form-control color-input-yellow"
                                   placeholder={(locale === "sk" && "Meno alebo miesto") || "Name or place"}
                                   onChange={handleChangeBigSearch}
                                   onKeyDown={handleBigKeyDown}
                                   type="text"/>
                        </div>
                    </div>
                    <div className={"d-flex rounded-bottom-2 color-green-d2 p-3"}>
                        <button className="btn btn-light ms-auto me-auto" data-method="get"
                                onClick={handleClickBigSearch}>
                            <p className={"m-0"}>
                                {(locale === "sk" && "Hladať") || "Search"}
                            </p>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
