import Header from "../../components/header/header";
import picture1 from "./images/picture1.jpg";
import picture1m from "./images/picture1-mini.jpg";
import picture1a from "./images/picture1a.jpg";
import picture1am from "./images/picture1a-mini.jpg";
import picture2 from "./images/picture2.jpg";
import picture2m from "./images/picture2-mini.jpg";
import picture3 from "./images/picture3.jpg";
import picture3m from "./images/picture3-mini.jpg";
import picture4 from "./images/picture4.jpg";
import picture4m from "./images/picture4-mini.jpg";
import picture5 from "./images/picture5.jpg";
import picture5m from "./images/picture5-mini.jpg";
import picture5a from "./images/picture5a.jpg";
import picture5am from "./images/picture5a-mini.jpg";
import picture6 from "./images/picture6.jpg";
import picture6m from "./images/picture6-mini.jpg";
import picture7 from "./images/picture7.jpg";
import picture7m from "./images/picture7-mini.jpg";
import picture8 from "./images/picture8.jpg";
import picture8m from "./images/picture8-mini.jpg";
import picture9 from "./images/picture9.jpg";
import picture9m from "./images/picture9-mini.jpg";
import picture10 from "./images/picture10.jpg";
import picture10m from "./images/picture10-mini.jpg";
import picture11 from "./images/picture11.jpg";
import picture11m from "./images/picture11-mini.jpg";
import picture12 from "./images/picture12.jpg";
import picture12m from "./images/picture12-mini.jpg";
import picture13 from "./images/picture13.jpg";
import picture13m from "./images/picture13-mini.jpg";
import picture13a from "./images/picture13a.jpg";
import picture13am from "./images/picture13a-mini.jpg";
import picture14 from "./images/picture14.jpg";
import picture14m from "./images/picture14-mini.jpg";
import picture15 from "./images/picture15.jpg";
import picture15m from "./images/picture15-mini.jpg";
import picture16 from "./images/picture16.jpg";
import picture16m from "./images/picture16-mini.jpg";
import picture17 from "./images/picture17.jpg";
import picture17m from "./images/picture17-mini.jpg";
import picture18 from "./images/picture18.jpg";
import picture18m from "./images/picture18-mini.jpg";
import picture19 from "./images/picture19.jpg";
import picture19m from "./images/picture19-mini.jpg";
import picture20 from "./images/picture20.jpg";
import picture20m from "./images/picture20-mini.jpg";
import picture21 from "./images/picture21.jpg";
import picture21m from "./images/picture21-mini.jpg";
import picture22 from "./images/picture22.jpg";
import picture22m from "./images/picture22-mini.jpg";
import picture23 from "./images/picture23.jpg";
import picture23m from "./images/picture23-mini.jpg";
import picture24 from "./images/picture24.jpg";
import picture24m from "./images/picture24-mini.jpg";
import picture25 from "./images/picture25.jpg";
import picture25m from "./images/picture25-mini.jpg";
import picture26a from "./images/picture26a.jpg";
import picture26am from "./images/picture26a-mini.jpg";
import picture26b from "./images/picture26b.jpg";
import picture26bm from "./images/picture26b-mini.jpg";
import picture27 from "./images/picture27.jpg";
import picture27m from "./images/picture27-mini.jpg";
import picture28 from "./images/picture28.jpg";
import picture28m from "./images/picture28-mini.jpg";
import picture29 from "./images/picture29.jpg";
import picture29m from "./images/picture29-mini.jpg";
import picture30 from "./images/picture30.jpg";
import picture30m from "./images/picture30-mini.jpg";
import picture31 from "./images/picture31.jpg";
import picture31m from "./images/picture31-mini.jpg";
import picture32 from "./images/picture32.jpg";
import picture32m from "./images/picture32-mini.jpg";
import picture33 from "./images/picture33.jpg";
import picture33m from "./images/picture33-mini.jpg";
import picture34 from "./images/picture34.jpg";
import picture34m from "./images/picture34-mini.jpg";
import picture34a from "./images/picture34a.jpg";
import picture34am from "./images/picture34a-mini.jpg";
import picture35 from "./images/picture35.jpg";
import picture35m from "./images/picture35-mini.jpg";
import picture35a from "./images/picture35a.jpg";
import picture35am from "./images/picture35a-mini.jpg";
import picture36 from "./images/picture36.jpg";
import picture36m from "./images/picture36-mini.jpg";
import picture37 from "./images/picture37.jpg";
import picture37m from "./images/picture37-mini.jpg";
import picture38 from "./images/picture38.jpg";
import picture38m from "./images/picture38-mini.jpg";
import picture39 from "./images/picture39.jpg";
import picture39m from "./images/picture39-mini.jpg";
import picture40 from "./images/picture40.jpg";
import picture40m from "./images/picture40-mini.jpg";
import picture41 from "./images/picture41.jpg";
import picture41m from "./images/picture41-mini.jpg";
import picture42 from "./images/picture42.jpg";
import picture42m from "./images/picture42-mini.jpg";
import picture43 from "./images/picture43.jpg";
import picture43m from "./images/picture43-mini.jpg";
import picture44 from "./images/picture44.jpg";
import picture44m from "./images/picture44-mini.jpg";
import picture44a from "./images/picture44a.jpg";
import picture44am from "./images/picture44a-mini.jpg";
import picture45 from "./images/picture45.jpg";
import picture45m from "./images/picture45-mini.jpg";
import picture46 from "./images/picture46.jpg";
import picture46m from "./images/picture46-mini.jpg";
import picture46a from "./images/picture46a.jpg";
import picture46am from "./images/picture46a-mini.jpg";
import picture47 from "./images/picture47.jpg";
import picture47m from "./images/picture47-mini.jpg";
import picture48 from "./images/picture48.jpg";
import picture48m from "./images/picture48-mini.jpg";
import picture49 from "./images/picture49.jpg";
import picture49m from "./images/picture49-mini.jpg";
import picture49a from "./images/picture49a.jpg";
import picture49am from "./images/picture49a-mini.jpg";
import picture50 from "./images/picture50.jpg";
import picture50m from "./images/picture50-mini.jpg";
import picture50a from "./images/picture50a.jpg";
import picture50am from "./images/picture50a-mini.jpg";
import picture51 from "./images/picture51.jpg";
import picture51m from "./images/picture51-mini.jpg";
import picture52 from "./images/picture52.jpg";
import picture52m from "./images/picture52-mini.jpg";
import {useContext, useState} from "react";
import {
    ArrowLeftCircleFill,
    ArrowRightCircleFill,
    XCircleFill
} from "react-bootstrap-icons";
import {LocaleContext} from "../../contexts/localecontext";

export default function Gallery() {
    const locale = useContext(LocaleContext);
    const [selectedImage, setSelectedImage] = useState(undefined)

    const images = [
        {
            big: picture1,
            small: picture1m,
            desc: "Následník trónu Rakúsko-Uhorskej konštitučnej dualistickej monarchie (1867 – 1918),\narcivojvoda František Ferdinand d'Este s manželkou grófkou Žofiou Chotkovou"
        },
        {
            big: picture1a,
            small: picture1am,
            desc: "Desiatnici Rudolf Viest (vľavo) a Ivan Markovič (vpravo) v čase vojenskej služby v 7. pešom pluku rakúsko-uhorskej armády (tiež c. a k. armády) v Štajerskom Hradci (Graz) v roku 1912"
        },
        {
            big: picture2,
            small: picture2m,
            desc: "Mobilizačná vyhláška 1914 v slovenskom jazyku",
        },
        {
            big: picture3,
            small: picture3m,
            desc: "Balkánske bojisko 1914. Budovanie improvizovaného mostu rakúsko-uhorskou armádou cez rieku Drinu",
        },
        {
            big: picture4,
            small: picture4m,
            desc: "Východný front, Baligród v Karpatoch v roku 1914\nRakúski vojaci skosení ruskými strojovými puškami (guľometmi)",
        },
        {
            big: picture5,
            small: picture5m,
            desc: "Rakúsko-uhorskí vojaci a obyvatelia Humenného po ústupe ruských vojsk z mesta\nKoniec novembra 1914",
        },
        {
            big: picture5a,
            small: picture5am,
            desc: "Príslušníci roty Nazdar, západný front 1914",
        },
        {
            big: picture6,
            small: picture6m,
            desc: "Dočasný ruský zajatecký tábor v Karpatoch v Šarišskej župe 1914/1915",
        },
        {
            big: picture7,
            small: picture7m,
            desc: "Strelecká línia rakúsko-uhorskej armády v Karpatoch",
        },
        {
            big: picture8,
            small: picture8m,
            desc: "Východný front. Poľný bitúnok rakúsko-uhorskej armády v Karpatoch, 19. marec 1915",
        },
        {
            big: picture9,
            small: picture9m,
            desc: "Sústreďovanie pechoty nemeckého „Beskiden Korps“ pri Zbudskej Belej (na území dnešného Slovenska) pred zahájením protiútoku v smere na Valentovce dňa 3. apríla 1915",
        },
        {
            big: picture10,
            small: picture10m,
            desc: "Poľná bohoslužba vojakov rakúsko-uhorskej armády, Klimentówka neďaleko mesta Luck, 1915",
        },
        {
            big: picture11,
            small: picture11m,
            desc: "Veliteľ 3. rakúsko-uhorskej armády, generál pechoty Svetozar Borojević von Bojna,\ndiskutuje s vyznamenanými vojakmi. Karpatský úsek východného frontu, jar 1915",
        },
        {
            big: picture12,
            small: picture12m,
            desc: "Zajatí ruskí vojaci pochodujú cez Prešov, 1915",
        },
        {
            big: picture13,
            small: picture13m,
            desc: "Srbský následník trónu Alexander I. Karađorđević, hlavný veliteľ srbských vojenských síl\nv rokoch 1. svetovej vojny, medzi českými a slovenskými dobrovoľníkmi, srbský front 1915",
        },
        {
            big: picture13a,
            small: picture13am,
            desc: "Príslušníci rozviedky 3. roty Českej družiny, pôsobiaci pri štábe 9. ruského armádneho zboru,\npo všeobecnom ústupe za rieku San, jar 1915",
        },
        {
            big: picture14,
            small: picture14m,
            desc: "V bojoch ranení rakúsko-uhorskí vojaci pri presune na poľné obväzisko v zápoli, 1915",
        },
        {
            big: picture15,
            small: picture15m,
            desc: "Príslušníci Českej družiny pri ústupe z priestoru slovenských Karpát, bezprostredne na to, ako unikli obkľúčeniu a hrozbe popravy za vlastizradu, máj 1915",
        },
        {
            big: picture16,
            small: picture16m,
            desc: "Rakúsko-uhorská pechota v zálohe v lese za frontovou líniou. Východný front 1915",
        },
        {
            big: picture17,
            small: picture17m,
            desc: "Škodové závody v Plzni, vojnová výroba. Ženy nahradili mužov, odvelených na bojiská svetovej vojny",
        },
        {
            big: picture18,
            small: picture18m,
            desc: "Rakúsky generálny štáb na talianskom fronte (tiež Sočsky front), počas tretej sočskej bitky,\n18. október - 4. november 1915",
        },
        {
            big: picture19,
            small: picture19m,
            desc: "Poručík Štefan Jurech (prvý sprava), príslušník 74. pešieho pluku Turčianskeho) rakúsko-uhorskej armády pred odchodom na front, rok 1916",
        },
        {
            big: picture20,
            small: picture20m,
            desc: "Poľná omša v 64. pešom pluku „rytiera von Auffenberga“ rakúsko-uhorskej armády v Brašove (Rumunsko) vo februári 1916. Krížikom pri nohe označený kadet Augustín Malár",
        },
        {
            big: picture21,
            small: picture21m,
            desc: "Útok rakúsko-uhorskej pechoty na talianske pozície na Sočskom fronte, 1916",
        },
        {
            big: picture22,
            small: picture22m,
            desc: "Predstava o budúcom Česko-Slovensku na mape brožúry, ktorú v roku 1917 vydala Francúzsko-česká liga. Názov propagovaného štátneho celku mal znel: České krajiny. Čechy, Morava, Sliezsko, Slovensko",
        },
        {
            big: picture23,
            small: picture23m,
            desc: "Delostrelectvo čs. streleckej brigády čs. légií v Rusku v boji pri Zborove 2. júla 1917",
        },
        {
            big: picture24,
            small: picture24m,
            desc: "Talianski zajatci čakajúci na transport do zajateckých táborov. Sočský front, leto 1917",
        },
        {
            big: picture25,
            small: picture25m,
            desc: "Užší výbor čs. dobrovoľníckeho zboru v Taliansku. Číslom 10 označený Ján Papánek",
        },
        {
            big: picture26a,
            small: picture26am,
            desc: "Plagáty vyzývajúce slovenských krajanov na vstup do čs. légií vo Francúzsku. Ich autorom bol Vojtěch Preissig, významný český grafik a maliar, účastník protirakúskeho a protinacistického odboja",
        },
        {
            big: picture26b,
            small: picture26bm,
            desc: "Plagáty vyzývajúce slovenských krajanov na vstup do čs. légií vo Francúzsku. Ich autorom bol Vojtěch Preissig, významný český grafik a maliar, účastník protirakúskeho a protinacistického odboja",
        },
        {
            big: picture27,
            small: picture27m,
            desc: "Vlaky s českými a slovenskými dobrovoľníkmi v Kanade",
        },
        {
            big: picture28,
            small: picture28m,
            desc: "Zľava americký vyslanec v Taliansku Thomas Nelson Page, generál Andrea Graziani, francúzsky vyslanec v Taliansku Camille Barrere, plukovník Milan Rastislav Štefánik a redaktor Dr. Lev Sychrava pri slávnostnej prehliadke 34 čs. streleckého pluku v kasárňach Ferdinanda Savojského v Ríme, 26. mája 1918",
        },
        {
            big: picture29,
            small: picture29m,
            desc: "Štyridsaťštyri popravených slovenských vojakov náhradného práporu 71. pešieho pluku,\nKragujevac 8. jún 1918",
        },
        {
            big: picture30,
            small: picture30m,
            desc: "Poľné obväzisko rakúsko-uhorskej armády na talianskom fronte na Piave roku 1918",
        },
        {
            big: picture31,
            small: picture31m,
            desc: "Improvizovaná práčovňa v rakúskych zákopoch na talianskom bojisku, 1918",
        },
        {
            big: picture32,
            small: picture32m,
            desc: "Poľná ošetrovňa. Očkovanie príslušníkov pešieho pluku 11 rakúsko-uhorskej armády\nna talianskom fronte, 1918",
        },
        {
            big: picture33,
            small: picture33m,
            desc: "Ústup c. a k. armády, útrapy vojnovej dopravy. Taliansky front, železničná stanica Bozen Gries (Bolzano), rok 1918",
        },
        {
            big: picture34,
            small: picture34m,
            desc: "Veliteľstvo práporu 21. čs. streleckého pluku čs. légií v Michelbachu\nna francúzsko-nemeckom fronte v Alsasku v roku 1918",
        },
        {
            big: picture34a,
            small: picture34am,
            desc: "Skupina guľometnej roty 23. čs. streleckého pluku čs. légii vo Francúzsku, Cognac december 1918",
        },
        {
            big: picture35,
            small: picture35m,
            desc: "Obrnený vlak čs. légií v Rusku „Spasitel“",
        },
        {
            big: picture35a,
            small: picture35am,
            desc: "Operačné oddelenie čs. vojska v Čeľjabinsku v júni 1918\nSlovenský legionár Ján Sýkora stojaci pri dverách (najvyšší)",
        },
        {
            big: picture36,
            small: picture36m,
            desc: "Diplom Slovenskej ligy v Amerike udeľovaný za finančnú pomoc čs. zahraničnému odboju",
        },
        {
            big: picture37,
            small: picture37m,
            desc: "Príslušníci brigády čs. légií vo Francúzsku za pochodu cez obec Semide v Champagni. Jeseň 1918",
        },
        {
            big: picture38,
            small: picture38m,
            desc: "Prísaha príslušníkov 39. čs. streleckého pluku čs. légií v Taliansku v Štilfskom sedle\nv talianskych dolomitoch v októbri 1918",
        },
        {
            big: picture39,
            small: picture39m,
            desc: "Titulný list časopisu Slovenské hlasy z 5. októbra 1918 (ústredného orgánu slovenského odboja v Rusku), ktorých šéfredaktorom bol od septembra 1918 Jozef Gregor-Tajovský",
        },
        {
            big: picture40,
            small: picture40m,
            desc: "Poslední talianski vojnoví zajatci na Piave v októbri 1918",
        },
        {
            big: picture41,
            small: picture41m,
            desc: "Slovenskí legionári, príslušníci 7. čs. streleckého pluku „Tatranského“ čs. légií v Rusku pred vyzdobeným vagónom na Transsibírskej železničnej magistrále v roku 1918",
        },
        {
            big: picture42,
            small: picture42m,
            desc: "Prvá československá vlajka vztýčená na Slovensku čs. dobrovoľníkmi, bývalými príslušníkmi 25. vlastibraneckého streleckého pluku. Železničná stanica Malacky, 4. november 1918",
        },
        {
            big: picture43,
            small: picture43m,
            desc: "Českí a slovenskí vojaci, bývalí príslušníci rakúsko-uhorskej armády, odchádzajú z talianskeho zajatia. November 1918",
        },
        {
            big: picture44,
            small: picture44m,
            desc: "Vojenské vyznamenania účastníkov prvého čs. odboja 1914 – 1918. V hornom rade zľava: Československý vojnový kríž; Medaila Jana Žižku z Trocnova „Za slobodu“; Československá revolučná medaila a Československá medaila víťazstvo. V dolnom rade: Rád M. R. Štefánika „Sokol“ s mečmi; Rád M. R. Štefánika „Sokol“ s hviezdou a Rád M. R. Štefánika „Sokol“ za zásluhy",
        },
        {
            big: picture44a,
            small: picture44am,
            desc: "Diplom Slovenskej ligy v Amerike udeľovaný za finančnú pomoc čs. zahraničnému odboju, 1918",
        },
        {
            big: picture45,
            small: picture45m,
            desc: "Príslušníci 5. čs. streleckého pluku „T. G. Masaryka“ čs. légií v Rusku vítajú generála Štefánika\nv Jekaterinburgu na uralskom fronte, 8. december 1918",
        },
        {
            big: picture46,
            small: picture46m,
            desc: "Vojenská misia čs. zahraničného vojska v decembri 1918 v Prahe. V prvom rade uprostred Pavel Varsik, po jeho pravici Rudolf Gábriš, zľava Gustáv Koštial a Jozef Martin Kristin",
        },
        {
            big: picture46a,
            small: picture46am,
            desc: "Generál M. R. Štefánik (v popredí uprostred) pri slávnostnom ceremoniáli udelenia ruského Rádu sv. Juraja čs. legionárskemu vojsku v Jekaterinburgu, 10. december 1918",
        },
        {
            big: picture47,
            small: picture47m,
            desc: "Odovzdanie bojovej zástavy 23. čs. streleckému pluku pred odchodom do vlasti,\nna námestí v Cognacu vo Francúzsku 22. decembra 1918\nPluk vznikol prevažne zo slovenských legionárov,\namerických dobrovoľníkov",
        },
        {
            big: picture48,
            small: picture48m,
            desc: "Hudba legionárskeho 7. čs. streleckého pluku Tatranského. Rusko, rok 1918, Sibírska magistrála",
        },
        {
            big: picture49,
            small: picture49m,
            desc: "Delostrelecká jednotka čs. légií z Talianska na Bratislavskom hrade začiatkom roka 1919",
        },
        {
            big: picture49a,
            small: picture49am,
            desc: "Čs. legionári z Talianska, príslušníci 32. čs. streleckého pluku v Rožňave, 1919",
        },
        {
            big: picture50,
            small: picture50m,
            desc: "Generál M. R. Štefánik pri prehliadke čs. domobraneckých práporov\nv Gallarate v Taliansku 28. apríla 1919",
        },

        {
            big: picture50a,
            small: picture50am,
            desc: "Z oslavy Nového roku 1920 na ďalekej Sibíri. Sediaci zľava veliteľ 12. čs. streleckého pluku\n„M. R. Štefánika“ major Josef Palacký a zástupca veliteľa pluku major Rudolf Viest",
        },
        {
            big: picture51,
            small: picture51m,
            desc: "Pamätník obetiam svetovej vojny v Bardejove na Slovensku",
        },
        {
            big: picture52,
            small: picture52m,
            desc: "Po obsadení Petržalky čs. vojskom v auguste 1919. Odstraňovanie drôtených prekážok",
        },
    ]

    const handleSelect = (i) => {
        setSelectedImage(i)
    }

    const handleNext = () => {
        setSelectedImage((selectedImage + 1) % images.length)
    }

    const handlePrev = () => {
        setSelectedImage((selectedImage + images.length - 1) % images.length)
    }

    if (selectedImage === undefined) {
        return (
            <>
                <Header/>

                {/*<div className={"container table table-responsive"}>*/}
                {/*    <table className={"table"}>*/}
                {/*        {images.map((d, n) => (*/}
                {/*            <tr key={n}>*/}
                {/*                <td>{n}</td>*/}
                {/*                <td>{d.small}</td>*/}
                {/*                <td>{d.big}</td>*/}
                {/*                <td>{d.desc}</td>*/}
                {/*            </tr>*/}
                {/*        ))}*/}
                {/*    </table>*/}
                {/*</div>*/}

                <div className={"container-w75 p-3 mx-auto font-elite"}>
                    <div className={"rounded-2 color-green-d3 p-3"}>
                        <div className={"d-flex flex-wrap justify-content-center align-items-center gap-3"}>
                            {images.map((d, dID) => (
                                <div key={dID} className="w-25 align-middle m-3">
                                    <img
                                        className="object-fit-cover w-100 h-auto align-middle border border-white border-3"
                                        src={d.small}
                                        alt=""
                                        onClick={() => handleSelect(dID)}
                                    />
                                </div>
                            ))}
                        </div>
                        <p>{locale === "sk" ? "zdroj ":"source "}: VHÚ-VHA Bratislava, VÚA -VHA Praha, Archív M SNP Banská Bystrica, zbierky autorov.</p>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <div className={"gal-x"}>
                <button className={"btn"} onClick={() => handleSelect(undefined)}>
                    <XCircleFill size={"2em"} color={"white"}/>
                </button>
            </div>

            <div className={"w-50 mx-auto d-flex justify-content-center align-items-center gal-fvh flex-column"}>
                <div className={"d-flex rounded-top-3 color-green-d2 w-100 justify-content-center align-items-center"}>

                    <div>
                        <button className={"btn"} onClick={handlePrev}>
                            <ArrowLeftCircleFill size={"2em"}/>
                        </button>
                    </div>
                    <div className={"m-3 align-middle gal-siw"}>
                        <img
                            alt=""
                            src={images[selectedImage].big}
                            className={"object-fit-contain w-100 h-100"}/>
                    </div>
                    <div>
                        <button className={"btn"} onClick={handleNext}>
                            <ArrowRightCircleFill size={"2em"}/>
                        </button>
                    </div>
                </div>

                <div className={"d-flex rounded-bottom-3 color-green-d1 w-100"}>
                    <div className={"p-3 text-center w-100"}>
                        {(locale === "sk" && (
                            <p className={"text-white m-0 ms-auto me-auto mt-1 mb-1 prewrap"}>
                                {images[selectedImage].desc}
                            </p>
                        )) || (
                            <p className={"text-white m-0 ms-auto me-auto mt-1 mb-1"}>
                                Image no. {selectedImage+1}
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
