import './background.css'
export default function Background() {
    return (
        <>
            <ul className="cb-slideshow">
                <li><span>Image 10</span></li>
                <li><span>Image 14</span></li>
                <li><span>Image 15</span></li>
                <li><span>Image 18</span></li>
                <li><span>Image 20</span></li>
                <li><span>Image 21</span></li>
                <li><span>Image 28</span></li>
                <li><span>Image 31</span></li>
                <li><span>Image 50</span></li>
                <li><span>Image 52</span></li>
            </ul>
        </>
    )
}