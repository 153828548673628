import {useContext} from "react";
import {LocaleContext} from "../../contexts/localecontext";
import {Navigate} from "react-router-dom";
import Header from "../../components/header/header";

export default function SkAndWW1() {
    const locale = useContext(LocaleContext);

    if (locale !== "sk") {
        return (
            <Navigate replace to={"/"}/>
        )
    }

    return (
        <>
            <Header/>
            <div className={"container-w75 p-3 mx-auto font-elite"}>
                <div className={"d-flex rounded-2 color-green-d3 p-3"}>
                    <div>
                        <h1 className={"pb-4"}>Slováci na frontoch prvej svetovej vojny</h1>

                        <p  className={"indented"}>Skúmanie problematiky vojenskej účasti Slovákov na bojových operáciách prvej svetovej vojny má v posledných dvoch desaťročiach na Slovensku vzrastajúcu tendenciu. Toto konštatovanie sa pritom týka nielen odbornej ale i laickej verejnosti. Rozhodne pozitívnymi výsledkami tejto interakcie sú tak nielen rôzne zamerané konferencie, výstavy, internetové stránky alebo nové publikácie. Mimoriadny význam majú aj ďalšie aktivity zamerané na obnovu vojnových cintorínov z obdobia „Veľkej“ vojny na Slovensku a taktiež ďalšie podujatia konkrétne pripomínajúce, resp. spredmetňujúce toto obdobie.</p>

                        <p  className={"indented"}>Ak sa zameriame na slovenskú vojenskú historiografiu do roku 1989 možno konštatovať, že tá v rámci interpretácie tejto problematiky primárne sledovala len niekoľko tematických okruhov. Podrobne sa skúmali najmä otázky ozbrojeného odporu Slovákov proti rakúsko-uhorskej monarchii, ďalej sociálne nepokoje a  vplyv ruskej októbrovej revolúcie z roku 1917 na situáciu v zázemí podunajskej monarchie.<sup>1</sup> Niektoré historické udalosti boli interpretované tendenčne, alebo boli spracované len v minimálnej miere (význam územia dnešného Slovenska pre vojnové operácie, účasť Slovákov na bojových operáciách v rámci rakúsko-uhorskej armády, alebo naopak v československom zahraničnom vojsku – légiách atď.).</p>

                        <p  className={"indented"}>Zmena politického režimu v roku 1989, ktorá priniesla pluralitu názorov aj v historiografii, mala rozhodujúci vplyv na to, že sa do centra pozornosti historikov začali dostávať aj niektoré dovtedy tabuizované alebo málo známe témy.</p>

                        <p  className={"indented"}>Cieľom nášho príspevku je priblížiť základné otázky súvisiace s vojenskou účasťou Slovákov na frontoch prvej svetovej vojny. Uvedomujeme si pritom, že pre limitované možnosti času a priestoru je možné podať len stručnú charakteristiku tohto problému.</p>

                        <p  className={"indented"}>Na úvod je potrebné uviesť, že podľa sčítania obyvateľstva z roku 1910 žili v Uhorsku približne 2 milióny Slovákov.<sup>2</sup> S tým potom priamo súviselo, že slovenskí vojaci tvorili v mierovej rakúsko-uhorskej armáde 3, 6 percenta jej celkového počtu. Čechov bolo 13 percent. Spomínané percentuálne zastúpenia Slovákov bolo takmer úplne tvorené brancami podliehajúcimi povinnej vojenskej službe. Vzhľadom na aktívnych a záložných dôstojníkov bola situácia diametrálne odlišná. Podľa štatistiky z roku 1911 sa z 1000 aktívnych dôstojníkov hlásil k slovenskej národnosti jeden dôstojník, kým k českej sa ich priznávalo 52. Z rovnakého počtu záložných dôstojníkov to bol opäť len jeden Slovák v porovnaní so 106 Čechmi.<sup>3</sup></p>

                        <p  className={"indented"}>Vzhľadom na jednotlivé zložky mierovej rakúsko-uhorskej armády mali Slováci najpočetnejšie zastúpenie  v delostrelectve (6 percent), v pechote (5 percent) a v rôznych tylových jednotkách (3 – 7 percent). Pomerne malé zastúpenie mali v jednotkách jazdectva (1 percento).<sup>4</sup> Ich zastúpenie napríklad v rakúsko-uhorskom vojnovom námorníctve je vzhľadom na celok síce marginálne (okolo 0, 4 percenta), no napriek tomu sú pre slovenskú vojenskú historiografiu zaujímavé aj tieto jednotlivé vojenské osoby.<sup>5</sup></p>

                        <p  className={"indented"}>Z konkrétnych vojenských jednotiek pechoty spoločnej rakúsko-uhorskej armády s najväčším zastúpením Slovákov v roku 1914 treba spomenúť 71. peší pluk s veliteľstvom a sídlom doplňovacieho okresu v Trenčíne. V tomto pluku slúžilo 85 percent Slovákov. Ide o vojenskú jednotku, ktorá dodnes symbolizuje nasadenie Slovákov na frontoch prvej svetovej vojny.<sup>6</sup></p>

                        <p  className={"indented"}>Územie dnešného Slovenska patrilo s drobnými odchýlkami do územnej kompetencie dvoch zborov rakúsko-uhorskej armády. Bol to V. zbor v Bratislave a VI. zbor v Košiciach.</p>

                        <p  className={"indented"}>Všeobecná mobilizácia rakúsko-uhorskej armády vyhlásená 31. júla 1914 prebehla, vzhľadom na jej slovenskú účasť, bez väčších problémov. Na tom mali podiel najmä silná religiozita vidieka, ďalej najmä vernosť a oddanosť Slovákov habsburskej dynastii.</p>

                        <p  className={"indented"}>Po začatí bojových operácií boli väčšie „slovenské“ jednotky obidvoch spomínaných zborov rakúsko-uhorskej armády nasadené na ruský front.</p>

                        <p  className={"indented"}>Už bitky pri Komarówe a Kraśniku na prelome augusta a septembra roku 1914 potvrdili, že Slováci sú v rámci rakúsko-uhorskej armády statoční, schopní a pritom nenároční vojaci. Ich bojové úspechy sa v prvej etape vojny odzrkadľovali nielen v slovenskej, ale dokonca aj v maďarskej a rakúskej tlači. Napríklad časopis Die Neue Zeitung konkrétne uviedol, že „...rozhodnutie vo veľkej bitke pri Komarówe priniesli Slováci pod velením starého rytiera generála Boroeviča“.<sup>7</sup> Maďarský časopis Alkotmány zas v roku 1915 konštatoval, že „...z roľníckeho stavu pochodiaci slovenský vojak je nepremožiteľný. Smelosťou, prísnou disciplínou a svojou poctivosťou sa Slováci stali elitným vojskom, ktoré sa hodí nielen na útok, ale svojou národnou povahou výborné je aj na obranu“.<sup>8</sup> Náčelník generálneho štábu Conrad von Hötzendorf v jednom zo svojich elaborátov pre panovníka zo 17. decembra 1915 o Slovákoch obdobne konštatoval, že „...bojovali hrdinsky a často javili oduševnené zmýšľanie, patriotizmus a vernosť dynastii.“<sup>9</sup></p>

                        <p  className={"indented"}>K problematike nasadenia Slovákov v prvom roku vojny možno konštatovať, že ich rakúska vojenská spravodajská služba hodnotila ako najspoľahlivejšiu národnosť rakúsko-uhorskej monarchie na fronte. Najmä v prvých rokoch vojny sa preto v slovenských národných a politických kruhoch objavila aj tendencia vyťažiť politický kapitál z uznávaného boja slovenských vojakov na fronte.<sup>10</sup> Konkrétne išlo o to, aby na oplátku obetavého boja v prospech vlasti a dynastie Slováci od budapeštianskej vlády dostali isté ústupky v nacionálnej, sociálnej a jazykovej oblasti.</p>

                        <p  className={"indented"}>Ak spomíname roky 1914 – 1915 možno spomenúť, že územie dnešného Slovenska sa práve v tomto období, hoci len na relatívne krátky čas, stalo priamym bojiskom prvej svetovej vojny. Bezprostredné frontové operácie v regióne dnešného severovýchodného Slovenska prebiehali od novembra 1914 do mája 1915.<sup>11</sup> Následkom týchto bojov padlo alebo v dôsledku zranení neskôr zomrelo viac ako 37 000 vojakov rakúsko-uhorskej, ruskej a nemeckej armády, ktorí sú pochovaní na 236 vojnových cintorínoch.<sup>12</sup></p>

                        <p  className={"indented"}>V porovnaní s ruským frontom sa Slováci v uniformách rakúsko-uhorskej armády dostali v prvých dvoch rokov vojny na srbský a taliansky front len v menšej miere. To súvisí okrem iného aj s tým, že väčšina jednotiek rakúsko-uhorskej armády so zastúpením Slovákov bola, a to až do roku 1916, nasadená práve na ruskom fronte.</p>

                        <p  className={"indented"}>V roku 1916, po vypuknutí vojny s Rumunskom boli niektoré rakúsko-uhorské útvary so zastúpením Slovákov prevelené aj na tento front. Od roku 1917 do konca prvej svetovej vojny sa do popredia dostalo ich nasadenie na talianskom  fronte. Slováci sa však v rámci niektorých útvarov spoločnej armády, uhorskej vlastibrany (honvédség) a ďalších zložiek rakúsko-uhorskej armády dostali aj na západný front, na albánsky front, do Palestíny a do niektorých iných „exotických“ oblastí. Napriek tomu, že v predvojnovom období bolo pomerne veľké množstvo Slovákov, ktorí počas celého života opúšťali rodnú obec len v celkom výnimočných prípadoch, predpokladáme, že takéto „cestovanie“ im nik nezávidel.</p>

                        <p  className={"indented"}>Už bolo spomenuté, že najmä v prvých dvoch rokoch trvania prvej svetovej vojny bolo nasadenie Slovákov na jej frontoch hodnotené prevažne pozitívne. V záverečnej fáze v rokoch 1917 – 1918 bola situácia už do istej miery odlišná. Na to malo vplyv najmä rapídne zhoršovanie zásobovania armády, ďalej vyčerpávajúce bojové operácie, zlá hospodárska situácia a taktiež niektoré ďalšie momenty. Tieto faktory všeobecne ovplyvnili vznik vzbúr v rakúsko-uhorskej armáde, na ktorých mali podiel aj Slováci.</p>

                        <p  className={"indented"}>Modelovým príkladom je už spomínaný 71. peší pluk. V roku 1914 sa vyznamenal v bojoch pri Kraśniku, Polichne a pred Lublinom, čo veľmi pozitívne hodnotil aj jeho vtedajší veliteľ plukovník Zikmund Prey.<sup>13</sup> O štyri roky neskôr – 2. júna 1918 večer došlo k vzbure náhradného práporu tohto pluku v srbskom Kragujevaci. Dôvody tejto vzbury boli rôzne. Jednak to bol návrat vojakov z ruského zajatia na jar 1918, predchnutých revolučnými ideami, ktorí boli zaradení do náhradného práporu. Ďalej to bola zlá zásobovacia situácia v náhradnom prápore samotnom, národnostná polarita medzi dôstojníkmi a mužstvom, tvrdosť výcviku s perspektívou nasadenia na fronte atď. Vzbura prebehla živelne, pričom je zaujímavé, že sa do nej miestne civilné srbské obyvateľstvo nezapojilo. Už nasledujúceho rána sa podarilo privolaným asistenčným jednotkám, a to aj po demonštratívnej paľbe delostreleckej batérie, vzburu zlikvidovať. Na základe stanného súdu bolo 44 účastníkov tejto vojenskej vzbury 8. júna 1918 v Kragujevaci na miestnej vojenskej strelnici popravených.<sup>14</sup></p>

                        <p  className={"indented"}>K ďalším vzburám menšieho rozsahu, na ktorých sa zúčastnili Slováci, došlo v tomto roku aj pri niektorých iných jednotkách.</p>

                        <p  className={"indented"}>Osobitnou kapitolou v rámci odporu proti rakúsko-uhorskej brannej moci boli dezertéri, ktorí sa skrývali na rôznych miestach v obciach i na vidieku. Ak do septembra 1918 boli v denných rozkazoch náhradných útvaroch pravidelne uvádzané mená vojenských osôb, ktoré svojvoľne opustili vojenskú posádku, od októbra 1918 vojenské orgány v zázemí na túto aktivitu v podstate rezignovali. Dezercie v rámci náhradných jednotiek vtedy nadobudli už masový charakter. Aj to bolo dôvodom, prečo niektoré vojenské posádky na území dnešného Slovenska prestali existovať už koncom septembra 1918. Fenomén dezertérstva – tzv. zelených kádrov – inak našiel uplatnenie aj v slovenskej krásnej literatúre po prvej svetovej vojne.</p>

                        <p  className={"indented"}>Počas poslednej rakúsko-uhorskej ofenzívy na Piave nasadilo velenie rakúsko-uhorskej armády znovu do boja aj viaceré jednotky s väčším zastúpením Slovákov. Aj tu opäť bojoval už viackrát spomínaný 71. peší pluk a ďalšie útvary.</p>

                        <p  className={"indented"}>Koncom októbra 1918 na talianskom fronte odmietli bojovať takéto viaceré, aj Slovákmi doplňované jednotky. Rovnako ako v zázemí tak aj na fronte sa začal proces rozpadu rakúsko-uhorskej armády. Napríklad 24. októbra 1918 odmietol nastúpiť do protiútoku v lokalite Mont Sisemol 25. pluk spoločnej armády z Lučenca. Jeho vojaci žiadali o návrat domov, o ďalšom bojovom nasadení odmietali „diskutovať“ a o niekoľko dní front aj samovoľne opustili.<sup>15</sup> Taliansky front sa v priebehu niekoľkých nasledujúcich dní rozpadol.</p>

                        <p  className={"indented"}>Slovenskí vojaci sa po rozpade rakúsko-uhorskej armády postupne spolu s ostatnými jej bývalými príslušníkmi začali vracať do svojich domovov. Mnohí z nich sa práve počas tohto návratu útržkovito dozvedali, že 28. októbra 1918 bola v Prahe vyhlásená Československá republika.</p>

                        <p  className={"indented"}>V súvislosti s prvou svetovou vojnou treba uviesť, že Slováci v sledovanom období nebojovali len v rakúsko-uhorskej armáde. Už od roku 1914 sa postupne začal formovať československý zahraničný odboj. Jeho ozbrojenou platformou sa postupne stalo československé zahraničné vojsko – légie.<sup>16</sup></p>

                        <p  className={"indented"}>Čelní predstavitelia zahraničného odboja: Slovák Milan Rastislav Štefánik a Česi Tomáš Garrigue Masaryk a Edvard Beneš si od počiatku svojej zahraničnej akcie s cieľom vytvorenia spoločného štátu Čechov a Slovákov uvedomovali význam samostatného vojska. To malo spolu s dohodovými armádami bojovať proti centrálnym mocnostiam.</p>

                        <p  className={"indented"}>Možnosti náboru dobrovoľníkov, ktorí boli ochotní bojovať za ideu budúceho československého štátu, však boli pomerne komplikované. Do úvahy prichádzali jednak pôvodní rakúsko-uhorskí zajatci nachádzajúci sa v zajateckých táboroch v Rusku, Srbsku, Francúzsku Taliansku, resp. v Rumunsku. Ďalej mohli rady zahraničnej armády posilniť aj emigranti, ktorí žili napríklad v Spojených štátoch amerických.</p>

                        <p  className={"indented"}>Začiatky výstavby československého zahraničného vojska boli z tohto dôvodu, čo sa týka personálneho zastúpenia, ťažké. Najprv boli vytvorené v podstate symbolické jednotky, ktoré pôsobili v rámci francúzskej a ruskej armády. Išlo konkrétne o rotu Nazdar bojujúcu v rámci francúzskej armády a o Českú družinu – súčasť ruskej armády.</p>

                        <p  className={"indented"}>Na rozvoj československej odbojovej akcie malo v roku 1915 významný vplyv podpísanie Clevelandskej dohody medzi vrcholnými predstaviteľmi amerických Slovákov a Čechov. Táto dohoda požadovala spojenie českého a slovenského národa do federatívneho zväzku štátov. Ďalšou dohodou medzi americkými Slovákmi a Čechmi, ktorou sa riešila otázka budúceho štátoprávneho usporiadania obidvoch týchto národov, bola Pittsburská dohoda z roku 1918.<sup>17</sup></p>

                        <p  className={"indented"}>V priebehu prvej svetovej vojny sa predstaviteľom československého zahraničného odboja podarilo vytvoriť československé légie v Rusku, Srbsku, Francúzsku a Taliansku. Pre bližšiu informáciu môžeme uviesť, že československých legionárov bolo celkovo v Rusku približne 71 000, v Taliansku okolo 20 000 a vo Francúzsku okolo 10 000.<sup>18</sup> Ak v roku 1914 predstavovali československé zahraničné vojsko len dve jednotky na úrovni roty, resp. práporu tak v roku 1918 to už bolo niekoľko divízií s počtom viac ako 100 000 „aktívnych bodákov“.</p>

                        <p  className={"indented"}>Najväčší počet Slovákov pritom slúžil v československých légiách v Rusku, približne 5000. V československých légiách v Taliansku slúžilo okolo 600 Slovákov, vo Francúzsku okolo 1600. Aj v československých légiách bol pritom evidentný nedostatok dôstojníkov – Slovákov (približne 120).<sup>19</sup> Všeobecne možno konštatovať, že tento stav existoval aj v československej brannej moci v rokoch 1918 – 1939.</p>

                        <p  className={"indented"}>Symbolom nasadenia československých légií v Rusku sa stala bitka pri ukrajinskom Zborove 2. júla 1917. K problematike pôsobenia československých legionárov v Rusku, resp. neskôr v sovietskom Rusku treba ešte konštatovať, že sa v roku 1918 ocitli v pomerne komplikovanej situácii. Po skončení prvej svetovej vojny považovali mnohí československí legionári svoje pôsobenie v Rusku za uzavreté a dožadovali sa rýchleho návratu do Československej republiky. Ďalšie zotrvávanie v Rusku, v čase keď sa tam naplno rozhorela občianska vojna, vnímali ako zbytočné.</p>

                        <p  className={"indented"}>Proces návratu československých légií z Ruska do vlasti bol pomerne komplikovaný. Posledné lodné transporty legionárov boli z ruského Vladivostoku realizované ešte v roku 1920. Viacerí legionári – Slováci si pritom v Rusku našli aj svoje životné partnerky.</p>

                        <p  className={"indented"}>Slováci sa v uniformách československých légií však zúčastnili aj bojov na talianskom fronte, napríklad na už spomínanej Piave, a na francúzskom fronte. Nie je bez zaujímavosti, že pri ich nasadení na viacerých frontových úsekoch dochádzalo k situáciám, keď bojovali proti vlastným krajanom v rovnošatách rakúsko-uhorskej armády.</p>

                        <p  className={"indented"}>Z najznámejších Slovákov – príslušníkov československých légií si pozornosť menovite zaslúžia napríklad Vladimír Svetozár Hurban, Ján Geryk, Vladimír Daxner, Janko Jesenský, Jozef Gregor Tajovský, Štefan Osuský, Ján Papánek, Rudolf Gábriš.</p>

                        <p  className={"indented"}>Číselné údaje, ktoré sme predtým uviedli, inak všeobecne poukazujú na menší počet Slovákov a Čechov v rovnošatách československých légií v porovnaní s ich účasťou v rakúsko-uhorskej armáde. Svoju úlohu pritom zohralo aj to, že v prípade zajatia rakúsko-uhorskou armádou boli legionári popravovaní ako vlastizradcovia.</p>

                        <p  className={"indented"}>Po vzniku Československej republiky v roku 1918 bola pozornosť spoločnosti i médií venovaná predovšetkým legionárom. Tí navyše požívali v novom štáte v rokoch 1918 – 1939 mimoriadnu úctu spoločnosti a rôzne výhody.</p>

                        <p  className={"indented"}>Z územia dnešného Slovenska bolo do rakúsko-uhorskej armády v priebehu prvej svetovej vojny zmobilizovaných približne 400 000 vojakov. Ohľadom strát sa uvádza, že ich padlo okolo 69 000 a vyše 61 000 bolo trvale zmrzačených.</p>

                        <p  className={"indented"}>Slováci v rokoch 1914 obliekali rovnošatu rakúsko-uhorskej armády, v menšej miere i československých légií. V rámci rakúsko-uhorskej armády bojovali najmä v prvých rokoch „Veľkej“ vojny statočne a s obdivuhodným elánom. V jej závere sa aj v prípade slovenskej účasti prejavila vyčerpanosť reprezentovaná vojenskými vzburami, dezerciami a odmietaním poslušnosti. Tí slovenskí vojaci, ktorí v priebehu vojny narukovali do rakúsko-uhorskej armády a prežili frontové nasadenie, sa po skončení vojny vracali do nového štátu – Československej republiky. Pre tých, ktorí sa nestali príslušníkmi československých légií ostávala aspoň útecha, že vojnu prežili.</p>

                        <p  className={"indented"}>Záverom možno ešte skonštatovať, že v dôsledku zmien na konci prvej svetovej vojny a rozpadu rakúsko-uhorskej monarchie sa prvýkrát v histórii objavil aj geografický, administratívny a politický termín Slovensko, ktorý dovtedy neexistoval. V porovnaní s predchádzajúcim obdobím sa v konštituovanej Československej republike zároveň diametrálne, k lepšiemu zmenili podmienky existencie slovenského národa.</p>

                        <div className={"d-flex gap-2 justify-content-end w-100"}>
                            <p className={"text-end"}>
                                plukovník Mgr. Miloslav Čaplovič, PhD.<br />
                                riaditeľ<br />
                                Vojenský historický ústav
                            </p>
                            <p className={"text-end"}>
                                &nbsp;
                            </p>
                            <p className={"text-end"}>
                                Mgr. Peter Chorvát, PhD.<br />
                                Vojenský historický ústav
                            </p>
                        </div>

                        <p style={{color: "#6c757d", fontSize: "0.75em"}}>
                            <sup>1</sup> HOLOTÍK, Ľ.: Sociálne a národné hnutie na Slovensku od Októbrovej revolúcie do vzniku československého štátu (Dokumenty). Bratislava : Veda 1979.; HOLOTÍK, Ľ.: Októbrová revolúcia a národnooslobodzovacie hnutie na Slovensku v rokoch 1917 – 1918. Bratislava : Slovenská akadémia vied 1958.; HRONSKÝ, M.: Vzbura slovenských vojakov v Kragujevci. Martin : Osveta 1982.<br />

                            <sup>2</sup> Bližšie pozri: Slovensko v 20. storočí, Prvý zväzok, Na začiatku storočia 1901 – 1914. Bratislava : Veda 2004, s. 45.<br />

                            <sup>3</sup> K národnostnej problematike v rakúsko-uhorskej armáde so zameraním na Slovákov bližšie pozri: ČAPLOVIČ, M.: Československé vzťahy v armáde 1918 – 1939, Význam československej brannej moci pre slovakizáciu Slovenska. In: Česko-slovenská historická ročenka. Brno – Bratislava 2009, s. 43 – 45.; DANGL, V.: Národnostná problematika v rakúsko-uhorskej armáde a Slováci. In: Československý časopis historický, roč. 24, 1976, č. 6, s. 851 – 882.; DANGL, V.: Pokusy o asimiláciu prostredníctvom armády a slovenské reakcie (1900 – 1914). In: Historik v čase a priestore. Bratislava : Veda 2000, s. 151 – 171.; HRONSKÝ, M. – KRIVÁ, A. – ČAPLOVIČ, M.: Vojenské dejiny Slovenska, IV. zväzok 1914 – 1939. Bratislava : MO SR, 1996, s. 26 – 27.<br />

                            <sup>4</sup> HRONSKÝ, M. – KRIVÁ, A. – ČAPLOVIČ, M.: Vojenské dejiny Slovenska, IV. zväzok 1914 – 1939. Bratislava : MO SR, 1996, s. 26 – 27.<br />

                            <sup>5</sup> Bližšie pozri: GRÉGR, R.: O zastoupení Slováků v c. a k. námořnictvu. In: Vojenská história, roč. 3, 1999, č. 4, s. 93 – 95.<br />

                            <sup>6</sup> Bližšie pozri: ŠVANDA, P.: Stručná história 71. pešieho pluku a jeho účasť na bojiskách prvej svetovej vojny. Trenčín : Kultúrne a metodické centrum OS SR 2008.<br />

                            <sup>7</sup> Národnie noviny 10. september 1914; článok prevzatý z Die Neue Zeitung.<br />

                            <sup>8</sup> Alkotmány 10. apríl 1915.<br />

                            <sup>9</sup> MARTINEK, F.: Conrad z Hötzendorfu o Slovákoch. In: Prúdy, roč. 12, 1928, č. 8, s. 494 – 495.<br />

                            <sup>10</sup> Pozri: HRONSKÝ, M.: Pohyb východného frontu (august 1914 – máj 1915) a jeho dosah a ohlas na Slovensku. In: Vojenská história, roč. 1, 1997, č. 1, s. 29.<br />

                            <sup>11</sup> Bližšie pozri: Prvá svetová vojna – Boje v Karpatoch. Humenné : Redos 2007.<br />

                            <sup>12</sup> KORBA, M.: Bojové operácie v okolí Humenného a Medzilaboriec. In: Mementá prvej svetovej vojny, Diel I., Cintoríny v okresoch Humenné a Medzilaborce. Humenné : Redos 2008, s. 7.<br />

                            <sup>13</sup> Krajan 24. december 1914.<br />

                            <sup>14</sup> HRONSKÝ, M.: Vzbura slovenských vojakov v Kragujevci. Martin : Osveta 1982.<br />

                            <sup>15</sup> HRONSKÝ – KRIVÁ – ČAPLOVIČ, ref. 4, s. 89.<br />

                            <sup>16</sup> K problematike československých légií všeobecne bližšie pozri: PICHLÍK, K. – KLÍPA, B. – ZABLOUDILOVÁ, J.: Českoslovenští legionáři 1914 – 1920. Praha : Mladá fronta 1996.<br />

                            <sup>17</sup> Texty oboch dohôd bližšie pozri: Pramene k dejinám Slovenska a Slovákov, XI b, Slováci v prvej svetovej vojne 1914 – 1918. Bratislava : Literárne informačné centrum 2010, s. 81 – 82, s. 248 – 249.<br />

                            <sup>18</sup> ČAPLOVIČ, M.: Československé vzťahy v armáde 1918 – 1939 Význam československej brannej moci pre slovakizáciu Slovenska. In: Česko-slovenská historická ročenka. Brno – Bratislava 2009, s. 44.<br />

                            <sup>19</sup> ČAPLOVIČ, ref. 18, s. 44 – 45.<br />
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}
