import Header from "../../components/header/header";
import {useContext, useEffect, useState} from "react";
import {LocaleContext} from "../../contexts/localecontext";

export default function SkInWW1() {
    const locale = useContext(LocaleContext);
    const [text, setText] = useState(null)

    useEffect(() => {
        if (locale === "sk") {
            setText((
                <div>
                    <h1 className={"pb-4"}>Slovensko v prvej svetovej vojne</h1>

                    <p className={"indented"}>Keď mesiac po Sarajevskom atentáte na rakúsko-uhorského následníka trónu
                        vyhlásilo o 11. hodine 28. júla 1914 Rakúsko-Uhorsko vojnu Srbsku, bolo zrejmé, že táto vojna sa
                        premenila na svetový konflikt. Znepriatelený svet úplne bezhlavo rozrazil brány 20. storočia,
                        storočia najkrvavejších vojen. Málokto si pred vyše 100 rokmi dokázal predstaviť, aká strašná
                        vojna začala, aká bude dlhá, ničivá a krutá a aké nedozierne budú jej dôsledky. „Veľkou vojnou“
                        sa jedna historická epocha končila a druhá začínala. Rozhodovalo sa v nej o budúcom usporiadaní
                        Európy a sveta, ale aj o budúcich osudoch malých národov. Do jej víru bolo postupne priamo alebo
                        nepriamo zapojených viac ako 30 štátov. Bojujúce štáty zmobilizovali obrovský počet vojakov,
                        ktorí bojovali a zomierali na jej frontoch v Európe, Ázii a aj v Afrike. Medzi týmito vojakmi
                        nechýbali ani Slováci.</p>

                    <p className={"indented"}>Po tom, čo 1. svetová vojna zmenila koncom roku 1914 svoj charakter z
                        ofenzívnej na pozičnú, sa na jej frontoch vytvoril systém zákopov, ktoré chránili vojakov v čase
                        medzi jednotlivými útokmi a veľkými ofenzívami. Na západnom fronte dosiahla dĺžka zákopov 750 km
                        od Severného mora až po švajčiarske hranice a podobne boli vybudované zákopy aj na východnom a
                        talianskom fronte. Zákopová vojna si vyžiadala vývoj nových druhov zbraní, ktoré mali prelomiť
                        sústavu zákopov a vyradiť z boja ich obranu. Išlo najmä o bojové plyny a plameňomety, či tanky.
                        Okrem pevniny sa bojovalo na moriach, pod ich hladinou číhali ponorky a vo vzduchu sa odohrávali
                        letecké súboje lietadiel, ktoré mali za sebou len pár rokov existencie.</p>

                    <p className={"indented"}>V bojujúcich krajinách, vrátane mnohonárodnej Rakúsko-Uhorskej monarchie,
                        sa vojnovej mašinérii podriadilo celé národné hospodárstvo, s dôrazom výrobu zbraní, munície,
                        trhavín a výbušnín, ostnatého drôtu, uniforiem i ďalšej výstroje. Okrem vojakov na frontoch sa
                        vojnová apokalypsa dotkla civilného obyvateľstva, osobitne žien a detí, či starších ľudí v
                        zázemí. Museli zmeniť svoj zaužívaný každodenný spôsob života, vyrovnať sa s odchodom mužov na
                        front a prispôsobiť sa zhoršeným podmienkam v časoch vojny. Svojou prácou nahradili chýbajúce
                        mužské pracovné sily v poľnohospodárstve, v priemysle a štátnej správe. Ženy sa napríklad
                        presadili aj ako úradníčky, poštárky, taxikárky, alebo vodičky električiek.</p>

                    <p className={"indented"}>Všeobecná mobilizácia rakúsko-uhorskej armády vyhlásená 31. júla 1914
                        prebehla, vzhľadom na jej slovenskú účasť, bez väčších problémov. Po začatí bojových operácií
                        boli aj jednotky, kde mali Slováci najväčšie percentuálne zastúpenie nasadené na ruský front.
                        Svojou pustošivou silou vojna rýchlo zasiahla aj územie dnešného Slovenska, ktoré vtedy
                        neexistovalo ako samostatný celok, ale bolo súčasťou Habsburskej monarchie. Mobilizačný rozkaz
                        odvelil postupne z tohto územia na bojiská prvej svetovej vojny okolo 400 000 mužov, teda asi
                        sedminu jeho vtedajšieho obyvateľstva. Z nich okolo 69 000 padlo a vyše 61 000 bolo trvale
                        zmrzačených. Ako vojaci rakúsko-uhorskej armády bojovali Slováci na ruskom, balkánskom,
                        talianskom, a koncom vojny i na západnom fronte. Svojou chrabrosťou, ktorú uznávali aj ich
                        velitelia, sa vyznamenali napríklad na ruskom fronte v bitkách pri Kraśniku a Komarowe, či
                        neskôr v Taliansku v bojoch na sočskom bojisku a počas záverečných ofenzív na Piave. Tisíce
                        Slovákov na týchto frontoch bojovali a umierali predovšetkým v peších, delostreleckých a
                        horských jednotkách, ako aj v práporoch poľných strelcov. Na sklonku Veľkej vojny sa však medzi
                        vojakmi začala prejavovať nespokojnosť a množili sa vzbury. V nemalej miere sa na nich
                        zúčastnili aj slovenskí vojaci, ktorí už odmietali ďalej bojovať pod habsburskou zástavou.
                        Vzbúrili sa napríklad v Bratislave, alebo v srbskom Kragujevci, kde za neúspešnú vzburu
                        zaplatilo 44 z nich svojím mladým životom. Rovnako materiálne škody boli obrovské a azda ešte
                        väčšie boli škody na ľudskej psychike. Nebola azda rodina, ktorej sa tragédia vojnovej
                        apokalypsy priamo nedotkla.</p>

                    <p className={"indented"}>Vojnovými udalosťami bola priamo zasiahnutá a spustošená aj časť územia
                        dnešného Slovenska. Krvavé boje v Karpatoch niesli aj pečať boja o hranice uhorskej vlasti, keď
                        pri postupe ruských vojsk v zime a na jar 1914 – 1915 sa spomínané oblasti stali priamo
                        operačným pásmom, bojiskom armád na východnom fronte. Ruskou armádou bolo okupovaných a
                        zničených niekoľko desiatok obcí na severovýchodnom Slovensku. Následkom bojov, ktoré v tomto
                        priestore prebiehali zahynulo takmer 50 000 vojakov na oboch znepriatelených stranách, ktorých
                        ostatky odpočívajú spolu v slovenskej zemi na zhruba 200 vojnových cintorínoch. Z obdobia rokov
                        1914 – 1918 sú na Slovensku aj lazaretné a zajatecké cintoríny (takmer 13 500 vojakov). Hlavne
                        na juhu západného Slovenska (Bratislava, Šamorín, Dunajská Streda, Veľký Meder, Komárno), ale aj
                        v Nitre, Trenčíne, či Košiciach.</p>

                    <p className={"indented"}>Slovenskí vojaci však v rokoch 1. svetovej vojny nebojovali len v
                        rakúsko-uhorskej armáde. Už na začiatku vojny na jeseň 1914 sa začali v zahraničí formovať
                        samostatné vojenské jednotky z českých a slovenských krajanov, ako rota Nazdar vo Francúzsku a
                        Česká družina v Rusku. Na sklonku roku 1915 sa v Paríži sformovalo vedenie československého
                        zahraničného odboja – Československá národná rada (Tomáš Garrigue Masaryk, Milan Rastislav
                        Štefánik a Edvard Beneš ). Jej činnosť smerovala k vytvoreniu spoločného štátu Čechov a Slovákov
                        a jedným zo spôsobov, ako túto ideu v štátoch Dohody presadiť, bolo vytvorenie dobrovoľníckeho
                        vojska – čs. légií, ktoré by bojovali po boku spojeneckých dohodových vojsk. Postupne sa tak
                        sformovali samostatné česko-slovenské légie v Rusku, vo Francúzsku a Taliansku, ktorých celkový
                        početný stav postupne dosiahol 100.743 Čechov a Slovákov. Krv čs. legionárov bola preliata v
                        bitkách pri Zborove a Bachmači, v bojoch na Somme a v Alsasku, na Piave i v bitke pri Doss Alte,
                        v bojoch o Samaru, Omsk, Irkutsk, Kazaň, Perm, či na ďalších bojiskách Veľkej vojny. Svojím
                        nasadením a účasťou v ťažkých bojoch na strane víťazných štátov, dláždili cestu k vytvoreniu
                        samostatnej Československej republiky 28. októbra 1918. Zrodila sa na sklonku „Veľkej vojny“ na
                        troskách rakúsko-uhorskej monarchie. K česko-slovenskej štátnosti sa prihlásili slovenskí
                        politickí reprezentanti Deklaráciou na zhromaždení v Martine 30. októbra 1918.</p>

                    <p className={"indented"}>Situácia krátko po skončení 1. svetovej vojny a vzniku Československej
                        republiky nebola jednoduchá. Územie Slovenska bolo naďalej pod kontrolou maďarských úradov a
                        vojska. Vojenské obsadzovanie jeho územia čs. domácim a legionárskym vojskom prebiehalo od
                        začiatku novembra 1918 vo viacerých etapách a skončilo sa až koncom januára 1919. Aj v
                        nasledujúcom období sa o Slovensko muselo bojovať s novovzniknutou boľševickou Maďarskou
                        republikou rád, ktorá nesúhlasila s demarkačnou čiarou medzi územím Slovenska a Maďarska. Boje
                        trvali od apríla do júna 1919, keď po rozhodnutí mierovej konferencie v Paríži, bola v
                        bratislavskej Redute 1. júla 1919 podpísaná dohoda o prímerí a maďarská armáda musela ustúpiť.
                        Vojenský zápas o Slovensko sa však definitívne skončil až obsadením územia bratislavského
                        predmostia - Petržalky v polovici augusta 1919. Išlo nielen o posledný akt vojenského konfliktu
                        medzi ČSR a Maďarskom, ale aj akýsi epilóg celého vojenského zápasu, ktorý museli čs. ozbrojené
                        sily zvádzať v rokoch 1918 – 1919 v nastolení poriadku v sudetských župách, v bojoch s poľským
                        vojskom o Tešínsko, ako aj s maďarskou Červenou armádou v bojoch o Slovensko a o podobu jeho
                        južnej hranice.</p>

                    <p className={"text-end"}>
                        plukovník Mgr. Miloslav Čaplovič, PhD.<br/>
                        riaditeľ<br/>
                        Vojenský historický ústav<br/>
                    </p>
                </div>
            ))
        } else {
            setText((
                <div>
                    <p className={"indented"}>On July 28th 1914, a month after the assassination of Archduke Franz
                        Ferdinand of Austria, the heir to the Austro-Hungarian throne, the Austria-Hungary declared a
                        war on Serbia. It was evident that this war will turn into a worldwide conflict. The alienated
                        world had mindlessly entered the 20th century, the century of the bloodiest wars. A hundred
                        years ago, barely anyone could’ve imagined how dreadful, destructive and cruel the war would be,
                        how long it will take to restore the order, and lastly how far reaching the outcome will be. The
                        Great War had marked the end of one epoch and the beginning of another one. Not only was the
                        fate of future European arrangement at stake but the future of tiny nations as well. Grip of the
                        war was gradually tightening around more than 30 states. The states at war mobilised enormous
                        number of soldiers who were fighting and dying on battle fronts in Europe, Asia and Africa. The
                        Slovak soldiers were among them as well.</p>

                    <p className={"indented"}>At the end of 1914, the WWI changed its character from offensive to
                        positional war. Adapting to this change had created a new system of defence, the system of
                        trenches which protected soldiers in between attacks and great offensives. At the western front,
                        the length of trenches reached to 750 km, stretching from the Northern Sea to the borders of
                        Switzerland. Similarly, the trenches were built at the Eastern and Italian front. The trench
                        warfare helped to further the development of new weaponry which was supposed to break the trench
                        system and to eliminate their defensive. These were mainly warfare gas and flamethrowers or
                        tanks. Except of land warfare, the war extended to the marine waters, where submarines were
                        lurking under the water surface. The sky was dominated by first aerial combats of aircrafts.</p>

                    <p className={"indented"}>The war machinery subdued the national economy with emphasis on making
                        weapons, ammunition, and variety of explosives, barbered wire, uniforms and other protective
                        gear, in all the war waging countries, including multinational Austria- Hungary. Apart from
                        soldiers fighting on the fronts, the war apocalypse touched lives of civilians, mainly women,
                        kids and elderly. They had to change the way of their lives, to cope with departure of men to
                        the front and to adjust to harsh conditions which any war brings. Their helping hands swiftly
                        substituted missing manpower in agriculture, industry and public affairs. For example, women
                        thrived as clerks, postmen, taxi drivers or tram drivers.</p>

                    <p className={"indented"}>General mobilisation of the Austria- Hungarian army, announced on July
                        31st 1914 regarding the Slovak part went swimmingly. After the launch of combat operations, the
                        troops comprised of a large percentage of Slovaks were deployed to the Russian front. The war
                        hit today’s territory of Slovakia (which at that time had not existed as a unitary state and
                        belonged under the territory of the Habsburg monarchy) with immense power. Under the
                        mobilisation order, around 400 000 men which equaled to one seventh of inhabitants, departed to
                        the WWI battlefields. Loss on lives counted to 69 000 and more than 61 000 were left crippled.
                        As soldiers of the Austro- Hungarian army, Slovaks fought on the Russian, Balkan, Italian front
                        and at the end of the war they joined battlefields on the western front. Their valour was
                        distinguished by their commanders. These troops excelled on the Russian front during battles of
                        Krasnik and Komarow. Later they proved their strength in Italy during battles of Sochi and the
                        final stages of the Piave offensive. Thousands of Slovaks on these battle fronts were fighting
                        and dying mainly in infantry, artillery and mountain units, as well as in the battalions of
                        field shooters. At the brink of the Great War, the troops began to show discontent and revolts
                        started to multiply. A number of Slovaks who rejected to fight under the Habsburg coat of arms
                        were among the insurgents. One of the uprising movements took place in Bratislava, another in
                        Kragujeva, Serbia. However, the Serbian attempt for uprising turned out to be a failure and 44
                        young men died. The material damage was great, though even greater was a damage to the human
                        psyche. With no doubts, there was not a single family which was not directly touched by the
                        tragedy of the war apocalypse. Part of the territory of today’s Slovakia was directly affected
                        and devastated by the war events. The bloodbaths carried by the combats in the Carpathian bore
                        the seal of the battle for homeland borders of Austria-Hungary. During the advancement of the
                        Russian troops in the winter and spring 1914 - 1915, the aforementioned areas became directly
                        the operational zone, the battlefield of the armies on the eastern front. Several municipalities
                        at northeast were occupied and destroyed by the Russian army. As a result of the fighting that
                        took place in this area, nearly 50,000 soldiers died on both sides. Their remains rest together
                        in the Slovak land located at about 200 war cemeteries. From the period of 1914 - 1918 there are
                        also hospital and prison cemeteries in Slovakia (counting up to 13 500 soldiers). They can be
                        found mainly in the south of western Slovakia (Bratislava, Šamorín, Dunajská Streda, Veľký
                        Meder, Komárno), but also in Nitra, Trenčín or Košice.</p>

                    <p className={"indented"}>However, the Slovak soldiers had not fought only in the Austria-Hungary
                        army. Already at the beginning of the war in the autumn of 1914, independent military units from
                        Czech and Slovak compatriots began to form abroad, such as the Nazdar unit in France and Česká
                        družina in Russia. At the end of 1915, the leadership of the Czechoslovak foreign resistance-
                        the Czechoslovak National Council represented by Tomáš Garrigue Masaryk, Milan Rastislav
                        Štefánik and Edvard Benešom was formed in Paris. Its activities were aimed at creating a common
                        state of Czechs and Slovaks and one of the ways how to enforce this idea in the states of the
                        Allied powers was the creating of volunteer troops of the Czechoslovak legions which would fight
                        alongside the Allied troops. Gradually, there had been formed the independent Czechoslovak
                        legions in Russia, France and Italy with the total number of 100 743 legionnaires. The blood of
                        the Czechoslovak legionaries was spilled on battlefields in Zborov and Bachmač, in Somme and
                        Alsace, Piave, during a fight at Doss Alt, Samara, Omsk Irkutsk, Kazan, Perm and more
                        battlefields of the Great War. Their commitment and participation in challenging battles on the
                        side of the victorious states, paved the way for the creation of an independent Czechoslovak
                        Republic on 28 October 1918. The Czechoslovak state was born at the end of the "Great War" on
                        the piles of rubble of the Austro-Hungarian monarchy. The Czechoslovak statehood was confirmed
                        by the Slovak political representatives by the Declaration at the Assembly in Martin on 30th
                        October 1918.</p>

                    <p className={"indented"}>The situation shortly after the end of the World War I and the
                        establishment of the Czechoslovak Republic was not easy. The Slovak territory was still under
                        the control of the Hungarian authorities and the army. The military occupation of the Slovak
                        territory by the Czechoslovak domestic and legionary troops took place from the beginning of
                        November 1918 in several stages and ended only at the end of January 1919. Even in the following
                        period, Slovakia had to fight the newly-established Bolshevik Republic of Hungary , which
                        disagreed with the demarcation line between the territory of Slovakia and Hungary. The fights
                        lasted from April to June 1919. After the decision made at the peace conference in Paris, a
                        ceasefire agreement was signed in Bratislava’s Reduta on July 1st, 1919 and the Hungarian army
                        had to withdraw. The military competition for Slovakia, however, finally ended with the
                        occupation of the Bratislava bridgehead - Petržalka in mid-August 1919. It was not only the last
                        military act between Czechoslovak republic and Hungary, but also a sort of epilogue of the whole
                        military combat that the Czechoslovak legionaries had to endure during the 1918- 1919 in order
                        to regain the order in the Sudeten counties. This included the fights with the Polish army for
                        the area of Tešínsko, as well as with the Hungarian Red Army and battles for Slovakia and its
                        southern border.</p>

                    <p className={"text-end"}>
                        Col. Miloslav Čaplovič, M.A., PhD.<br/>
                        The Institute of Military History
                    </p>
                </div>
            ))
        }
    }, [locale, setText]);


    return (
        <>
            <Header/>
            <div className={"container-w75 p-3 mx-auto font-elite"}>
                <div className={"d-flex rounded-2 color-green-d3 p-3"}>
                    {text}
                </div>
            </div>
        </>
    )
}
