import Background from "./components/background/background";
import {LocaleContext, LocaleContextSet} from "./contexts/localecontext";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Root from "./pages/root/root";
import About from "./pages/about/about";
import Skandww1 from "./pages/skandww1/skandww1";
import Search from "./pages/search/search";
import Gallery from "./pages/gallery/gallery";
import SkInWW1 from "./pages/skinww1/skinww1";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root/>,
        errorElement: <Root/>,
    },
    {
        path: "/about",
        element: <About/>,
    },
    {
        path: "/sk-in-ww1",
        element: <SkInWW1 />,
    },
    {
        path: "/sk-and-ww1",
        element: <Skandww1/>,
    },
    {
        path: "/search",
        element: <Search/>,
    },
    {
        path: "/gallery",
        element: <Gallery/>,
    },
]);

export default function App() {
    const [locale, setLocale] = useState("xx")

    useEffect(() => {
        if (locale === "xx") {
            const lang = navigator.language || navigator.userLanguage;
            if (lang.startsWith("sk") || lang.startsWith("cz")) {
                setLocale("sk")
            } else {
                setLocale("en")
            }
        }
    }, [locale, setLocale]);

    return (
        <>
            <Background/>
            <LocaleContext.Provider value={locale}>
                <LocaleContextSet.Provider value={setLocale}>
                    <RouterProvider router={router}/>
                </LocaleContextSet.Provider>
            </LocaleContext.Provider>
        </>
    )
}