import './search.css';
import React, {useCallback, useContext, useEffect, useState} from "react";
import Pagination from 'react-bootstrap/Pagination';
import API from "../../services/api";
import {LocaleContext} from "../../contexts/localecontext";
import Header from "../../components/header/header";
import {useSearchParams} from "react-router-dom";

export default function Search() {
    const locale = useContext(LocaleContext);
    const [searchField, setSearchField] = useState("");
    const [searchDisabled, setSearchDisabled] = useState(false);
    const [searchP, setSearchP] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [maxPage, setMaxPage] = useState(0);
    const [pageItems, setPageItems] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if(!loaded) {
            setLoaded(true)
            let q = searchP.get("q") ?? ""
            setSearchField(q)
        }
    }, [searchP, loaded, setLoaded, setSearchField]);

    useEffect(() => {
        let q = searchP.get("q") ?? ""
        let page = parseInt(searchP.get("page") ?? "1")

        setLoading(true)

        API.search(locale === "sk" ? "sk" : "en", q, page).catch((e) => {
            console.log(e)
            setData([])
            setMaxPage(1)
            setSearchDisabled(false)
            setLoading(false)
        }).then((data) => {
            setData(data.data.items)
            setMaxPage(data.data.last_page)
            setLoading(false)
        })
    }, [locale, searchP, setData, setMaxPage, setSearchDisabled, setLoading]);

    const handleClickSearch = useCallback(() => {
        setSearchDisabled(true)
        setSearchP((p) => {
            p.set("q", searchField)
            p.set("page", "1")
            return p
        })
    }, [searchField, setSearchP]);

    const handleSearchField = (e) => {
        setSearchField(e.target.value)
        setSearchDisabled(false)
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleClickSearch()
        }
    };

    const setPageC = useCallback((c)=>{
        return () => {
            setSearchP((p) => {
                p.set("page", c.toString())
                return p
            })
        }
    }, [setSearchP]);

    useEffect(() => {
        let page = parseInt(searchP.get("page") ?? "1")
        let paginator_first_item = 0;
        let paginator_last_item = 0;
        let items = [];

        items.push(
            <Pagination.First key="first" disabled={page < 2} onClick={setPageC(1)}/>
        )
        items.push(
            <Pagination.Prev key="prev" disabled={page < 2} onClick={setPageC(page - 1)}/>
        )

        if (page - 3 < 1) {
            paginator_first_item = 1
            paginator_last_item = maxPage > 7 ? 7 : maxPage
        } else {
            paginator_first_item = page - 3
            if (page + 3 > maxPage) {
                paginator_last_item = maxPage
                paginator_first_item = maxPage - 6 < 1 ? 1 : maxPage - 6
            } else {
                paginator_last_item = page + 3
            }
        }

        for (let n = paginator_first_item; n <= paginator_last_item; n++) {
            items.push(
                <Pagination.Item key={n} active={n === page} onClick={setPageC(n)}>{n}</Pagination.Item>
            )
        }

        items.push(
            <Pagination.Next key="next" disabled={page >= maxPage} onClick={setPageC(page + 1)}/>
        )
        items.push(
            <Pagination.Last key="last" disabled={page >= maxPage} onClick={setPageC(maxPage)}/>
        )

        setPageItems(items)
    }, [searchP, maxPage, setPageItems, setPageC]);

    return (
        <>
            <Header/>

            <div className={"container-w75 p-3 mx-auto font-elite"}>
                <div className={"rounded-top-2 color-green-d3 p-3 w-100"}>
                    <div className={"row"}>
                        <div className={"col-10"}>
                            <input aria-describedby="search-button" className="form-control color-input-yellow pt-2"
                                   placeholder={(locale === "sk" && "Meno alebo miesto") || "Name or place"}
                                   onChange={handleSearchField}
                                   onKeyDown={handleKeyDown}
                                   value={searchField}
                                   type="text"/>
                        </div>
                        <div className={"col-2"}>
                            <button className="form-control btn btn-light pt-2" data-method="get"
                                    disabled={searchDisabled}
                                    onClick={handleClickSearch}>
                                <p className={"m-0"}>
                                    {loading ?
                                        ((locale === "sk" && "Hladanie...") || "Searching...")
                                        :
                                        ((locale === "sk" && "Hladať") || "Search")
                                    }
                                </p>
                            </button>
                        </div>
                    </div>
                </div>
                <div className={"d-flex rounded-bottom-2 color-green-d3 p-3 pt-2 flex-column"}>
                    <div className="table-responsive mt-1 w-100 rounded-top-3">
                        <table className="table">
                            <thead className="table-head search-table-header">
                            <tr>
                                <th className="col-2 align-middle search-table-header">
                                    {(locale === "sk" && "Priezvisko") || "Surname"}
                                    <br/>
                                    {(locale === "sk" && "Meno") || "Given name"}
                                </th>
                                <th className="col-2 align-middle search-table-header">
                                    {(locale === "sk" && "Hodnosť") || "Rank"}
                                </th>
                                <th className="col-2 align-middle search-table-header">
                                    {(locale === "sk" && "Kmeňová jednotka") || "Regiment"}
                                </th>
                                <th className="col-2 align-middle search-table-header">
                                    {(locale === "sk" && "Narodenie") || "Birth"}
                                </th>
                                <th className="col-2 align-middle search-table-header">
                                    {(locale === "sk" && "Úmrtie") || "Death"}<br />
                                    {(locale === "sk" && "Miesto pochovania") || "Place of burial"}
                                </th>
                                <th className="col-2 align-middle search-table-header">
                                    {(locale === "sk" && "Matrika") || "Register"}
                                </th>
                            </tr>
                            </thead>
                            <tbody className="table-body" id="body-sk">
                            {data.map((row, rowID) => (
                                <tr key={rowID}>
                                    <td className={"search-table-row"}>{row.surname} {row.given_name}</td>
                                    <td className={"search-table-row"}>{row.rank}</td>
                                    <td className={"search-table-row"}>{row.regiment}</td>
                                    <td className={"search-table-row"}>{row.birth_date}<br/>{row.birth_place}</td>
                                    <td className={"search-table-row"}>{row.death_date}<br/>{row.death_place}</td>
                                    <td className={"search-table-row"}>{row.register}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>

                    <div className={"d-flex justify-content-center"}>
                        <Pagination size="sm">
                            {pageItems}
                        </Pagination>
                    </div>
                </div>
            </div>
        </>
    )
}