import axios from "axios";

const search = (locale, q, page) => {
    return axios.get(`/api/query`, {
        params: {
            locale: locale,
            lang: locale,
            q: q,
            page: page
        }
    })
};

const API = {
    search
};

export default API;