import logo from './logo.png'
import logowebp from './logo.webp'
import './header.css'
import {Link, useLocation} from "react-router-dom";
import {LocaleContext, LocaleContextSet} from "../../contexts/localecontext";
import {useCallback, useContext, useState} from "react";
import {List} from "react-bootstrap-icons";

export default function Header() {
    const location = useLocation();
    const [menuShow, setMenuShow] = useState(false)
    const locale = useContext(LocaleContext);
    const localeSet = useContext(LocaleContextSet);

    const menuClass = "menuitem border-white"
    const menuTextClass = "p-1 pt-2"
    const classIfMe = (c) => {
        if (location.pathname === c) {
            return " border border-2"
        }
        return ""
    }

    const toggleMenu = useCallback(() => {
        setMenuShow(!menuShow)
    }, [menuShow, setMenuShow])

    return (
        <div className={"header font-elite"}>
            <div className={"title-lang2"}>
                <div className={"text-white"}>
                    <a className={"btn text-white"} href={"https://theworldremembers.org/"}>The World Remembers</a>
                    |
                </div>
                <div className={"text-white"}>
                    <button
                        className={"btn text-white" + (locale === "en" ? " text-decoration-underline" : "")}
                        onClick={() => localeSet("en")}>
                        EN
                    </button>
                    |
                    <button
                        className={"btn text-white" + (locale === "sk" ? " text-decoration-underline" : "")}
                        onClick={() => localeSet("sk")}>
                        SK
                    </button>
                </div>
            </div>
            <Link className="logo" to={"https://www.vhu.sk"}>
                <div className={"logo-inner"}>
                    <picture>
                        <source type="image/webp" srcSet={logowebp}/>
                        <source type="image/jpeg" srcSet={logo}/>
                        <img src={logo} alt=""/>
                        </picture>

                </div>
                <div className={"vhutitle"}>
                        {(locale === "sk" && (
                            <>
                                Vojenský<br/>
                                historický<br/>
                                ústav
                            </>
                        )) || (
                            <>
                                Institute of<br/>
                                Military<br/>
                                History
                            </>
                        )
                        }
                </div>
            </Link>

            <div className={"title-out"}>
                <div className={"title-lang1"}>
                    <div className={"text-white"}>
                        <a className={"btn text-white"} href={"https://theworldremembers.org/"}>The World Remembers</a>
                        |
                    </div>
                    <div className={"text-white"}>
                        <button
                            className={"btn text-white" + (locale === "en" ? " text-decoration-underline" : "")}
                            onClick={() => localeSet("en")}>
                            EN
                        </button>
                        |
                        <button
                            className={"btn text-white" + (locale === "sk" ? " text-decoration-underline" : "")}
                            onClick={() => localeSet("sk")}>
                            SK
                        </button>
                    </div>
                </div>
                {/*<div className={"title-title"}>*/}
                    <Link className={"title-title"} to={"/"}>
                    {(locale === "sk" && (
                        <>
                            SVET SI PAMÄTÁ<br/>
                            medzinárodný projekt
                        </>
                    ) || (
                        <>
                            THE WORLD REMEMBERS<br/>
                            international project
                        </>
                    ))}
                    </Link>
                {/*</div>*/}
                <div className={"title-menu-toggle"}>
                    <btn className={"btn text-white shadow-none"} onClick={toggleMenu}>
                        <List size={"2em"}></List>
                    </btn>
                </div>
                <div className={"title-menu" + (menuShow ? "": " title-menu-hide")}>
                    {locale === "sk" && (
                        <div className={menuClass}>
                            <Link className={menuTextClass + classIfMe("/about")} to={"/about"}>
                                {(locale === "sk" && "O projekte") || "About"}
                            </Link>
                        </div>
                    )}
                    {locale === "sk" && (
                        <div className={menuClass}>
                            <Link className={menuTextClass + classIfMe("/sk-and-ww1")} to={"/sk-and-ww1"}>
                                {(locale === "sk" && "Slováci na frontoch prvej svetovej vojny") || "Slovaks on the fronts of the World War I"}
                            </Link>
                        </div>
                    )}
                    <div className={menuClass}>
                        <Link className={"search-menu " + menuTextClass + classIfMe("/search")} to={"/search"}>
                            {(locale === "sk" && "Hľadať") || "Search"}
                        </Link>
                    </div>
                    <div className={menuClass}>
                        <Link className={menuTextClass + classIfMe("/gallery")} to={"/gallery"}>
                            {(locale === "sk" && "Galéria") || "Photo gallery"}
                        </Link>
                    </div>
                    <div className={menuClass}>
                        <Link className={menuTextClass + classIfMe("/sk-in-ww1")} to={"/sk-in-ww1"}>
                            {
                                (locale === "sk" && (
                                    <>Slovensko v prvej svetovej vojne</>
                                ) || (
                                    <>Slovakia in World War I</>
                                ))
                            }
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}