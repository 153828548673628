import Header from "../../components/header/header";
import {useContext} from "react";
import {LocaleContext} from "../../contexts/localecontext";
import {Navigate} from "react-router-dom";

export default function About() {
    const locale = useContext(LocaleContext);

    if (locale !== "sk") {
        return (
            <Navigate replace to={"/"}/>
        )
    }

    return (
        <>
            <Header/>
            <div className={"container-w75 p-3 mx-auto font-elite"}>
                <div className={"d-flex rounded-2 color-green-d3 p-3"}>
                    <div>
                        <h1 className={"pb-4"}>Projekt k obetiam 1. svetovej vojny</h1>

                        <p className={"indented"}>Vojenský historický ústav (ďalej „VHÚ“) navštívil dňa 19. novembra
                            2019 zakladateľ a ambasádor projektu „Svet si pamätá“ pán Robert Holmes Thomson. Počas
                            pracovnej návštevy oboznámil predstaviteľov Vojenského historického ústavu s cieľom
                            projektu, ktorého realizáciu finančne podporila vláda Kanady a odbornú záštitu mu poskytlo
                            Kanadské vojnové múzeum v Ottawe. Zámerom medzinárodného projektu, do ktorého sa doposiaľ
                            aktívne zapojilo 19 krajín sveta, je predstaviť mená mužov a žien, ktorí sa stali obeťami 1.
                            svetovej vojny, a to bez rozdielu, na ktorej strane stáli. V súčasnosti je v databáze 5 000
                            000 mien osôb, ktorí zomreli v rokoch 1914 až 1922 a pochádzali zo Spojeného kráľovstva,
                            Kanady, Francúzska, Nemecka, Spojených štátov amerických, Turecka, Belgicka, Austrálie,
                            Českej republiky, Ukrajiny, Talianska, Nového Zélandu, Slovinska, Indie, Pakistanu,
                            Bangladéša a Číny.
                            (https://theworldremembers.org/countries/slovakia/slovakia-the-first-world-war).</p>

                        <p className={"indented"}>VHÚ ako vedecko-výskumné, archívne a múzejné zariadenie Ministerstva
                            obrany SR pre oblasť vojenskej histórie uvítala uvedený medzinárodný projekt venovaný
                            pamiatke obetí prvej z dvojice svetových vojen v histórii ľudstva. VHÚ poskytol
                            medzinárodnému projektu odborný príspevok o pôsobení Slovákov na bojiskách prvej svetovej
                            vojny, 267 zdigitalizovaných vojenských matrík padlých vojakov – príslušníkov
                            rakúsko-uhorskej armády pochádzajúcich z územia dnešného Slovenska. Na základe dodatočnej
                            požiadavky z kanadskej strany zo septembra 2020 sme začali aj s prepisovaním matrík do
                            podoby databázy. Bol vytvorený vedecký projekt „Databáza padlých vojakov z obdobia 1.
                            svetovej vojny (1914 – 1918)“, na plnenie ktorého bolo vyčlenených 8 pracovníkov Vojenského
                            historického archívu.</p>

                        <p className={"indented"}>Projekt predpokladá prepisovanie 267 vojenských matrík uložených vo
                            VHA Bratislava podľa vopred danej štruktúry (meno a priezvisko, hodnosť, kmeňová jednotka,
                            dátum úmrtia, dátum narodenia, miesto pochovania, príčina smrti, miesto narodenia). Vyžaduje
                            od riešiteľov okrem poznania základných historických reálií aj jazykové znalosti, pretože
                            matriky sú písané v rôznych jazykoch (slovenský, český, maďarský, nemecký).</p>

                        <p className={"indented"}>V tomto roku, keď si pripomíname 105. výročie ukončenia
                            jedného z najhorších vojenských konfliktov v dejinách ľudstva, chceme verejnosti predstaviť
                            databázu padlých vojakov – príslušníkov rakúsko-uhorskej armády pochádzajúcich z územia
                            dnešného Slovenska z obdobia 1. svetovej vojny. Je to prvá etapa, v ktorej sme do databázy
                            zaradili niečo viac ako 30 000 mien padlých. Databáza bude v nasledujúcich mesiacoch a
                            rokoch postupne doplňovaná. V databáze je možné vyhľadávať podľa mena a priezviska, dátumu a
                            miesta narodenia, kmeňovej vojenskej jednotky, dátumu úmrtia alebo miesta pochovania. V
                            databáze je pri každom mene uvedená aj matrika, z ktorej údaje pochádzajú, takže je možné
                            prípadne dohľadať bližšie údaje k danej osobe.</p>

                        <p className={"indented"}>VHÚ chce touto cestou vzdať hold a úctu obetiam 1. svetovej vojny bez
                            ohľadu na to, na ktorej strane barikády vo vojne stáli.</p>

                        <p className={"text-end"}>
                            Text: Mgr. Peter Kralčák, PhD.<br/>
                            riaditeľ<br/>
                            Vojenský historický archív
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}
